import { Grid, Typography, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import WesProfilePicture from "../assets/images/wes-garvin-profile-picture.png";

const Hero = () => {
  return (
    <Box
      sx={{
        height: { xs: "100%", md: "100%", xl: "100%" },
      }}
      style={{
        background: `linear-gradient(
            to bottom,
            #4a4e69 0%,
            #4a4e69 75%,
            #333649 75%,
            #333649 100%
          )`,
      }}
    >
      <Box>
        <Grid
          container
          justifyContent="center"
          sx={{ p: 5, pb: { xs: 5, md: 0 } }}
        >
          <Grid
            item
            sx={{
              display: { xs: "block", md: "block", lg: "flex" },
            }}
            alignItems="center"
          >
            <Zoom in={true} style={{ transitionDelay: "750ms" }}>
              <Box
                sx={{
                  pl: { xs: 0, md: 0, xl: 10 },
                  pb: { xs: 0, md: 5, xl: 0 },
                  textAlign: { md: "center", xl: "left" },
                }}
              >
                <Typography variant="h1" sx={{ color: "secondary.main" }}>
                  <strong>I'm Wes Garvin</strong>
                </Typography>
                <Typography variant="h3" color="white">
                  Front-end Web Developer
                </Typography>
              </Box>
            </Zoom>
            <Box
              sx={{
                display: { xs: "none", md: "block", lg: "flex", xl: "flex" },
                height: { md: "650px", xl: "850px" },
              }}
            >
              <img
                height="100%"
                src={WesProfilePicture}
                alt="wes-garvin-profile"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Hero;
