import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import About from "../assets/images/about-me.png";

const AboutMe = () => {
  return (
    <Box
      id="about"
      sx={{ backgroundColor: "primary.dark", py: { xs: 5, md: 10, lg: 15 } }}
      height="100%"
    >
      <Container maxWidth="xl">
        <Typography variant="h3" color="white" sx={{ pb: 7, pt: 5 }}>
          About Me
        </Typography>
        <Grid container display="flex">
          <Grid item>
            <Box display="flex" width="100%" alignItems="center">
              <Box sx={{ display: { xs: "none", lg: "flex" } }}>
                <img src={About} alt="about" width="750px" />
              </Box>
              <Typography
                variant="h6"
                color="white"
                height="100%"
                sx={{ p: 5 }}
              >
                I am a self-taught front-end web developer with experience in
                React, JavaScript, Git, HTML, and CSS. Through self-directed
                learning, I have acquired the skills to create visually
                appealing and functional web applications. My proficiency in
                React and JavaScript enables me to build dynamic and responsive
                user interfaces, while my knowledge of Git allows me to
                collaborate effectively with other developers. I also have a
                solid understanding of HTML and CSS, which allows me to create
                well-structured and visually appealing web pages. With my
                skillset and experience, I am confident in my ability to
                continue growing as a front-end developer.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutMe;
