import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Grid, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

const Skills = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const skills = [
    {
      label: "Problem Solver",
      value: "1",
      content:
        "I take pride in my problem-solving skills which have enabled me to tackle complex coding challenges effectively. I approach each problem with a clear and logical mindset, breaking down the issue into manageable pieces and considering all possible solutions. My experience has taught me that the key to effective problem-solving is to first identify and analyze the root cause of a problem, and then to devise effective solutions to resolve it. I have honed my skills over time, and I am now adept at troubleshooting issues that may arise during the development process. Utilizing my technical knowledge and attention to detail, I am able to find and fix any errors that may arise in the code. My problem-solving skills, combined with my technical expertise, allow me to deliver high-quality, well-functioning web applications that meet the needs of clients and users alike.",
    },
    {
      label: "Responsive Designer",
      value: "2",
      content:
        "I have a strong ability to create responsive web applications that adapt seamlessly to different screen sizes and devices. I possess an in-depth understanding of the CSS library Material UI, which allows me to design and develop applications that are not only visually appealing but also highly functional. Material UI provides me with an extensive collection of pre-built React components that allow me to create consistent and responsive web designs with ease. However, I am confident that I am able to learn and apply other CSS frameworks as needed to future projects. My expertise in media queries allows me to create responsive designs that adjust to a wide range of screen sizes, providing users with an optimal experience regardless of the device they are using. My commitment to creating responsive web applications ensures that users can interact with the application seamlessly, no matter what device they choose to use.",
    },
    {
      label: "Creative Entrepreneur",
      value: "3",
      content:
        "I possess an entrepreneurial mindset that allows me to approach projects with a unique perspective. I am always looking for ways to innovate and create new solutions that meet the needs of clients and users alike. My entrepreneurial mindset drives me to take ownership of the projects I work on, and I am constantly seeking out opportunities to create value for clients and help them achieve their business objectives. I am comfortable taking risks and making bold decisions, always keeping in mind the ultimate goal of delivering high-quality, user-friendly web applications. I am constantly learning and keeping up-to-date with the latest trends and technologies in the industry, and I use this knowledge to create innovative solutions that are both efficient and effective. My entrepreneurial mindset is a key asset in the ever-changing landscape of web development, and it allows me to continuously adapt and grow as a front-end web developer.",
    },
  ];

  return (
    <Box
      id="skills"
      sx={{
        backgroundColor: "primary.light",
        height: { xs: "100%", lg: "100%" },
        py: { xs: 5, md: 10, lg: 15 },
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" textAlign="center" sx={{ pb: 7, pt: 5 }}>
          Skills
        </Typography>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Grid item>
            <TabContext value={value}>
              <Box sx={{ width: { xs: "90vw", lg: "70vw" } }}>
                <TabList
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  scrollButtons
                  variant="scrollable"
                >
                  {skills.map((item) => (
                    <Tab
                      label={item.label}
                      value={item.value}
                      key={item.value}
                      sx={{ fontSize: "1.25em" }}
                    />
                  ))}
                </TabList>
                {skills.map((item) => (
                  <TabPanel
                    key={item.value}
                    sx={{ fontSize: "1.25em" }}
                    value={item.value}
                  >
                    {item.content}
                  </TabPanel>
                ))}
              </Box>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Skills;
