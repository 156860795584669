import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SweatPlan from "../assets/images/sp-500px.png";
import NationalParkTrivia from "../assets/images/npt-500px.png";
import AtlantaDoctor from "../assets/images/ad-500px.png";
import MobileMechanic from "../assets/images/mm-500px.png";

const Projects = () => {
  const projects = [
    {
      name: "National Park Trivia",
      description:
        "This mobile-responsive trivia app was built using JavaScript, HTML, and CSS, and incorporates local storage for scorekeeping. This project challenged me to think logically and utilize concepts such as bubbling and capturing, DOM manipulation, media queries, and array methods. This app was one of my first projects as a full-time student and provided me with invaluable hands-on experience. I persevered until I achieved a polished and highly functional final product, which has been a source of pride and a testament to my proficiency in these languages.",
      link: "https://nationalparktrivia.wesgarvin.dev/",
      repo: "https://github.com/wgarv100/np-trivia/",
      youtube: null,
      image: NationalParkTrivia,
    },
    {
      name: "Sweat Plan",
      description:
        "This workout planner app enables users to plan their workout by searching for exercises based on specific keywords such as target muscles or body parts through an API. It provides the convenience of saving the workout routine to a server, which can be easily accessed at the gym. The app also features GIFs of the exercises, allowing users to recall how to perform them accurately. Developing this app allowed me to master core React concepts such as useState, useEffect, and useContext, as well as learn how to effectively pass props down a component tree.",
      link: null,
      repo: "https://github.com/wgarv100/react-api-search",
      youtube: "https://www.youtube.com/watch?v=suUWVuWCrCk",
      image: SweatPlan,
    },
    {
      name: "Atlanta Doctor",
      description:
        "Developing this app as a freelance project gave me the opportunity to demonstrate my proficiency in front-end web development and entrepreneurship, while delivering a practical solution to a real-world client with a deadline. By utilizing React and Material UI, I was able to create a visually stunning and responsive app that not only featured react router and embedded Google Maps but also allowed me to expand my knowledge of building aesthetically pleasing and functional apps. Throughout this project, I honed my skills in using components such as cards, grids, and responsive hero images, enabling me to deliver a truly exceptional user experience.",
      link: "https://atlantadoctor.wesgarvin.dev/",
      repo: null,
      youtube: null,
      image: AtlantaDoctor,
      chip: "Repository available upon request",
    },
    {
      name: "Mobile Mechanic",
      description:
        "I leveraged my creative and entrepreneurial skills, along with my expertise in React and Material UI, to build a stunning single-page website for a local business. Through effective communication, I understood the client's requirements and created a website that not only enhanced their brand image but also boosted their sales. By incorporating components like a parallax image and slider, I was able to showcase their business information in a visually appealing and user-friendly way. Overall, this project allowed me to demonstrate my ability to deliver exceptional results by combining technical expertise with effective communication and creative problem-solving skills.",
      link: "https://mobilemechanic.wesgarvin.dev/",
      repo: null,
      youtube: null,
      image: MobileMechanic,
      chip: "Repository available upon request",
    },
  ];

  return (
    <Box
      id="projects"
      height="100%"
      sx={{ backgroundColor: "primary.light", pb: { xs: 5, md: 10, lg: 15 } }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ pb: 7, pt: 5 }} textAlign="center">
          Projects
        </Typography>
        <Grid
          container
          spacing={5}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {projects.map((item) => (
            <Grid item key={item.name}>
              <Card sx={{ maxWidth: 500 }} elevation={10}>
                <CardMedia
                  component="img"
                  width="720px"
                  image={item.image}
                  alt={item.name}
                />
                <CardContent sx={{ height: "100%", py: 3 }}>
                  <Typography variant="h4" paddingBottom={2}>
                    {item.name}
                  </Typography>
                  <Typography variant="p">{item.description}</Typography>
                  <Box paddingTop={2}>
                    {item.link === null ? null : (
                      <IconButton href={item.link} target="_blank">
                        <OpenInNewIcon />
                      </IconButton>
                    )}
                    {item.repo === null ? (
                      <Chip
                        label={item.chip}
                        sx={{ backgroundColor: "secondary.main" }}
                      />
                    ) : (
                      <IconButton href={item.repo} target="_blank">
                        <GitHubIcon />
                      </IconButton>
                    )}
                    {item.youtube === null ? null : (
                      <IconButton href={item.youtube} target="_blank">
                        <YouTubeIcon />
                      </IconButton>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
export default Projects;
