import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import AboutMe from "./components/AboutMe";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Languages from "./components/Languages";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

const theme = createTheme({
  palette: {
    primary: {
      light: "#6e7187",
      main: "#4a4e69",
      dark: "#333649",
    },
    secondary: {
      light: "#d3bdb8",
      main: "#c9ada7",
      dark: "#8c7974",
    },
  },
  typography: {
    h1: {
      fontFamily: ["Paytone One"],
    },
  },
});

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Hero />
        <AboutMe />
        <Languages />
        <Skills />
        <Projects />
        <Contact />
      </ThemeProvider>
    </>
  );
};

export default App;
