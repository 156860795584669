import { Box } from "@mui/system";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHtml5,
  faCss3,
  faJs,
  faReact,
  faGit,
} from "@fortawesome/free-brands-svg-icons";
import { Container, Grid, Typography } from "@mui/material";
import "../App.css";

const Languages = () => {
  const languages = [
    { name: "HTML", icon: faHtml5, size: "10x" },
    { name: "CSS", icon: faCss3, size: "10x" },
    { name: "JavaScript", icon: faJs, size: "10x" },
    { name: "React", icon: faReact, size: "10x" },
    { name: "Git", icon: faGit, size: "10x" },
  ];

  return (
    <Box
      id="languages"
      sx={{
        height: { xs: "100%", lg: "100%" },
        py: { xs: 5, md: 10, lg: 15 },
        backgroundColor: "primary.main",
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="h3" textAlign="center">
          What I Know
        </Typography>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {languages.map((item) => (
            <Grid item key={item.name} textAlign="center">
              <Box sx={{ p: 7, pb: 5 }}>
                <FontAwesomeIcon icon={item.icon} size={item.size} />
              </Box>
              <Typography variant="h4">{item.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Languages;
