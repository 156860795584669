import { AppBar, Button, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Resume from "../assets/files/wes-garvin-official-resume.pdf";

const Navbar = () => {
  const sections = [
    { name: "About Me", anchor: "#about" },
    { name: "Languages", anchor: "#languages" },
    { name: "Skills", anchor: "#skills" },
    { name: "Projects", anchor: "#projects" },
    { name: "Contact", anchor: "#contact" },
    { name: "Resume", anchor: Resume },
  ];
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{ display: { xs: "none", md: "block" } }}
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        {sections.map((item) => (
          <Box
            key={item.name}
            sx={{
              display: { xs: "none", md: "flex" },
              px: 1,
            }}
          >
            <Button href={item.anchor} color="inherit">
              {item.name}
            </Button>
          </Box>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
