import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const Contact = () => {
  const contact = [
    {
      name: "github",
      link: "https://github.com/wgarv100",
      icon: <GitHubIcon sx={{ fontSize: 85 }} />,
    },
    {
      name: "linkedin",
      link: "https://www.linkedin.com/in/wes-garvin-4509a4166/",
      icon: <LinkedInIcon sx={{ fontSize: 85 }} />,
    },
    {
      name: "email",
      link: "mailto:garvinwes@gmail.com",
      icon: <EmailIcon sx={{ fontSize: 85 }} />,
    },
  ];
  return (
    <Box
      id="contact"
      sx={{
        backgroundColor: "primary.main",
        height: { xs: "100%", lg: "100%" },
        py: { xs: 5, md: 10, lg: 10 },
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          textAlign: "center",
        }}
      >
        <Grid item>
          <Typography variant="h2">Get in touch</Typography>
          <Grid item sx={{ p: 5 }}>
            {contact.map((item) => (
              <IconButton key={item.name} href={item.link} target="_blank">
                {item.icon}
              </IconButton>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
